<template>
  <el-dialog
    :style="'--width:' + width"
    class="select-dialog"
    v-bind="$props"
    v-on:update:visible="onClose"
  >
    <slot v-bind:close="close" v-bind:submit="submit"></slot>
  </el-dialog>
</template>
<script>
export default {
  props: {
    width: {
      type: String,
      default: '920px'
    },
    title: {
      type: String,
      default: '请选择'
    },
    visible: Boolean
  },
  data() {
    const vm = this
    return {
      close() {
        vm.onClose()
      },
      submit(...arg) {
        vm.onClose()
        vm.$emit('submit', ...arg)
      }
    }
  },
  methods: {
    onClose() {
      this.$emit('update:visible', false)
    }
  }
}
</script>
<style lang="scss" scoped>
.select-dialog {
  /deep/ .el-dialog {
    min-width: 920px;
    min-width: var(--width);
  }
  /deep/ .el-dialog__header {
    padding: 16px 15px;
    border-bottom: 1px solid #eee;
  }
  /deep/ .el-dialog__body {
    padding: 10px 15px 0;
  }
}
</style>
